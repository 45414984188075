import { useState } from 'react';
import { useRouter } from 'next/router';

import useLanguage from './useLanguage';
import { API_BASE_URL } from '../../config.js';

const useMeta = () => {
	const { t } = useLanguage();

	const [defaultMeta] = useState({
		title: t('meta_home_title'),
		description: t('meta_home_description'),
		keywords: t('meta_home_keywords'),
		image: `${API_BASE_URL}project/drazbe/template/client/assets/meta/mojedrazbe-com.webp`
	});

	// IMPORTANT: When added new, see: \drazbe\data\loader-optimistic-cache\readme.txt
	const [metaByPathname] = [{
		// pathname: lngid "id" // ex. meta_auction_list_title => meta_<id = auction_list>_title
		'/drazbe': 'auction_list',
		'/mojedrazbe-pro': 'landing_pro',
		'/cenik': 'pricelist',
		'/login': 'auth_login',
		'/register': 'auth_register',
		'/forgot-password': 'auth_forgot_password',
		'/404': 'error_404',
		'/o-nas/kontakt': 'contact',
		'/o-nas/oglasevanje': 'advertising',
		'/o-nas/splosni-pogoji-uporabe': 'terms_privacy'
	}];
	// IMPORTANT: When added new, see: \drazbe\data\loader-optimistic-cache\readme.txt

	const router = useRouter();

	const getMetaByPathname = () => {
		const { asPath } = router;
		const m = metaByPathname[asPath] || null;

		return {
			title: m ? t(`meta_${m}_title`) : defaultMeta.title,
			description: m ? t(`meta_${m}_description`) : defaultMeta.description,
			keywords: m ? t(`meta_${m}_keywords`) : defaultMeta.keywords,
			image: defaultMeta.image
		};
	};

	const getMeta = (data = null) => {
		// Set meta by pathname (ex. /drazbe) - if not exist, defaultMeta is applyed
		const m = getMetaByPathname();

		// Override with data (ex. detail page)
		if (data !== null) {
			if (data.meta_title) 				m.title = data.meta_title;
			if (data.meta_description) 	m.description = data.meta_description;
			if (data.meta_keywords) 		m.keywords = data.meta_keywords;
			if (data.meta_image) 				m.image = data.meta_image;
		}

		return m;
	};

	return { getMeta, getMetaByPathname };
};

export default useMeta;
